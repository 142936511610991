import React, { useEffect, useMemo, useState } from 'react'
import {
  Form,
  Input,
  Select,
  message,
  Checkbox,
  Slider,
  InputNumber,
  Radio,
  Tooltip,
} from 'antd'
import 'react-quill/dist/quill.snow.css'
import moment from 'moment'
import {
  valuesSetHandler,
  CompensationType,
  jobFunctions,
  jobCategories,
} from '../../../data'
import {
  cleanObject,
  getDescription,
  permissionsHandler,
} from '../../../functions'
import { useDispatch, useSelector } from 'react-redux'
import { strapiURL } from '../../../config'
import axios from 'axios'
import { navigate } from 'gatsby'
import { setProducts } from '../../../redux/actions/user'
import GooglePlacesAutocomplete from '../../google-place-autocomplete'
import { geocodeByPlaceId } from 'react-google-places-autocomplete'
import { getAddressObject } from '../../../functions'
import { CloseOutlined } from '@ant-design/icons'
import EmployerJobPreview from '../../Modals/employerJobPreview'

const FORM_METHOD_SUBMIT = 'FORM_METHOD_SUBMIT'
const FORM_METHOD_DRAFT = 'FORM_METHOD_DRAFT'
const FORM_METHOD_PREVIEW = 'FORM_METHOD_PREVIEW'

const JobEditComponent = ({ user, userProfile, location }) => {
  let jobId = location?.pathname.split('edit/')[1]
  const [submitLoading, setSubmitLoading] = useState(false)
  const [draftLoading, setDraftLoading] = useState(false)
  const [minMaxSalary, setMinMaxSalary] = useState([0, 0])
  const [minMaxSalaryCopy, setMinMaxSalaryCopy] = useState([])
  const [compensation, setCompensation] = useState([0, 0])
  const [compensationCopy, setCompensationCopy] = useState([])
  const [formMethod, setFormMethod] = useState(FORM_METHOD_SUBMIT)
  const [currentJob, setCurrentJob] = useState({})
  const [id, setId] = useState(null)
  const [locationRequired, setLocationRequired] = useState(true)
  const [externalRequired, setExternalRequired] = useState(false)
  const [checkDescription, setCheckDescription] = useState('')
  const [applicationMethod, setApplicationMethod] = useState('')
  const [jobPreview, setJobPreview] = useState()
  const [form] = Form.useForm()
  const { Option } = Select
  const storeProducts = useSelector(state => state.user).products
  const userData = useSelector(state => state.user)
  const dispatch = useDispatch()
  const [jobLocationList, setJobLocationList] = useState([])

  let products = permissionsHandler(storeProducts, user?.role?.id)

  let ReactQuill
  if (typeof window !== 'undefined') {
    ReactQuill = require('react-quill')
  }
  useEffect(() => {
    form.setFieldsValue({
      description: '',
    })
    if (jobId) {
      axios.get(strapiURL + `/jobs/${jobId}`).then(res => {
        setCurrentJob(res?.data)

        setId(res?.data?.id)

        setMinMaxSalary([res?.data?.minSalary || 0, res?.data?.maxSalary || 0])
        setMinMaxSalaryCopy([
          res?.data?.minSalary || 0,
          res?.data?.maxSalary || 0,
        ])
        setCompensation([
          res?.data?.min_compensation || 0,
          res?.data?.max_compensation || 0,
        ])
        setCompensationCopy([
          res?.data?.min_compensation || 0,
          res?.data?.max_compensation || 0,
        ])
        if (Array.isArray(res?.data?.location)) {
          setJobLocationList(
            (res?.data?.location || []).map(item => item.placePredict)
          )
        } else {
          if (res?.data?.location) {
            setJobLocationList([res?.data?.location?.placePredict])
          } else {
            setJobLocationList([])
          }
        }
        setCheckDescription(res.data.description || '')
        setApplicationMethod(res?.data?.application_method)
        setExternalRequired(res?.data?.external_redirect)
        form.setFieldsValue({
          title: res?.data?.title || '',
          description: res.data.description || '',
          job_code: res?.data?.job_code || '',
          remote_enabled: res?.data?.remote_enabled || false,
          field_based: res?.data?.field_based || false,
          exclusive_medreps: res?.data?.exclusive_medreps || false,
          external_redirect: res?.data?.external_redirect || false,
          applicationUrl: res?.data?.applicationUrl || '',
          application_email: res?.data?.application_email || '',
          min_max: [res?.data?.minSalary || 0, res?.data?.maxSalary || 0],
          compensation: [
            res?.data?.min_compensation || 0,
            res?.data?.max_compensation || 0,
          ],
          primary_function: res?.data?.primary_function || '',
          product_category: res?.data?.product_category || '',
          compensation_type: res?.data?.compensation_type || '',
          travel_percentage: res?.data?.travel_percentage || '',
          application_method: res?.data?.application_method || '',
          confidential: res?.data?.confidential,
        })
        if (res?.data?.remote_enabled) {
          setLocationRequired(false)
        }
      })
    }
  }, [])

  useEffect(() => {
    if (
      checkDescription === '<p><br></p>' ||
      checkDescription.split(' ').join('') === '<p></p>'
    ) {
      form.setFieldsValue({
        description: '',
      })
    }
  }, [checkDescription])

  const submitHandler = async values => {
    if (values.applicationUrl && !values.applicationUrl.includes('http')) {
      form.setFields([
        {
          name: 'applicationUrl',
          errors: ['Enter a valid http or https url.'],
        },
      ])
      return
    }
    if (formMethod === FORM_METHOD_DRAFT) {
      setDraftLoading(true)
    } else if (formMethod === FORM_METHOD_SUBMIT) {
      setSubmitLoading(true)
    }

    let createdJob = {
      ...values,
      customer_email: user?.username || '',
      employer: userProfile?.id,
      minSalary: minMaxSalary[0],
      maxSalary: minMaxSalary[1],
      min_compensation: compensation[0],
      max_compensation: compensation[1],
      description: getDescription(values.description),
      external_redirect_count: currentJob
        ? currentJob.external_redirect_count
        : 0,
    }

    /* Location Process */
    const locationList = []
    if (jobLocationList && jobLocationList.length > 0) {
      for (const locItem of jobLocationList) {
        const geocodeData = await geocodeByPlaceId(locItem.value.place_id)
        if (geocodeData[0]) {
          const addressObject = getAddressObject(
            geocodeData[0].address_components
          )
          const locationObject = {
            location: {
              ...addressObject,
              formattedAddress: geocodeData[0].formatted_address,
              latitude: geocodeData[0].geometry.location.lat(),
              longitude: geocodeData[0].geometry.location.lng(),
              place_id: locItem.value.place_id,
            },
            placePredict: locItem,
          }
          locationList.push(locationObject)
        }
      }
    }
    createdJob.location = locationList

    if (formMethod === FORM_METHOD_PREVIEW) {
      setJobPreview(createdJob)
      return
    }

    if (!isActiveJob) {
      createdJob.published_at = new Date()
    }

    createdJob.remote_enabled = values.remote_enabled ? true : false
    createdJob.field_based = values.field_based ? true : false
    createdJob.exclusive_medreps = values.exclusive_medreps ? true : false

    const userId =
      user?.role?.name === 'Named User'
        ? userData?.profileData?.user?.id
        : user?.user?.id
    axios
      .put(strapiURL + `/jobs/${id}`, createdJob)
      .then(res => {
        axios
          .get(strapiURL + `/employers/${userId}/subscriptionsAndCredits`)
          .then(response => {
            dispatch(setProducts(response?.data))
            message.success(
              formMethod === FORM_METHOD_DRAFT
                ? 'Job saved as draft'
                : 'Your job has been published and will be live on MedReps shortly.'
            )
            navigate('/employer-dashboard/jobs/manage/', location)
            setCurrentJob(res?.data)
            setDraftLoading(false)
            setSubmitLoading(false)
          })
          .catch(err => {
            setDraftLoading(false)
            setSubmitLoading(false)
            message.error(
              err?.response?.data?.message || 'Something went wrong.'
            )
          })
      })
      .catch(err => {
        setDraftLoading(false)
        setSubmitLoading(false)
        console.log(err)
        message.error(err?.response?.data?.message || 'Something went wrong.')
      })
  }

  const isActiveJob = useMemo(() => {
    if (
      currentJob.published_at &&
      !currentJob?.archived_date &&
      new Date() <= moment(currentJob.published_at).add(30, 'days').toDate()
    ) {
      return true
    } else {
      return false
    }
  }, [currentJob])
  const jobBtnLabel = useMemo(() => {
    if (isActiveJob) {
      if (submitLoading) {
        return 'Updating...'
      } else {
        return 'Update'
      }
    } else {
      if (submitLoading) {
        return 'Posting...'
      } else {
        return 'Post Job'
      }
    }
  }, [isActiveJob, submitLoading])

  const handleRemoveLoc = index => {
    jobLocationList.splice(index, 1)
    setJobLocationList([...jobLocationList])
  }

  return (
    <div>
      <h1 className="font-playfair text-headingThree font-semibold text-merlot">
        Edit A Job
      </h1>
      <div>
        <Form
          className="pt-12 flex flex-col lg:flex-wrap justify-between w-full lg:w-8/12"
          form={form}
          name="create-job-form"
          layout="vertical"
          onFinish={submitHandler}
        >
          <Form.Item
            name="title"
            label="Job Title"
            className="theme-form-item w-full mb-8"
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
          >
            <Input
              type="text"
              placeholder="Enter Job Title"
              className="placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
            />
          </Form.Item>

          <Form.Item
            className="theme-form-item w-full mb-8"
            name="description"
            label="Job Description"
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
          >
            {typeof window !== 'undefined' && (
              <ReactQuill
                onChange={content => {
                  setCheckDescription(getDescription(content))
                }}
                defaultValue=""
                className="quill-editor job-editor placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
                theme="snow"
                placeholder="Enter Job Description"
              />
            )}
          </Form.Item>

          <Form.Item
            name="job_code"
            label="Job Code"
            className="theme-form-item w-full mb-8"
          >
            <Input
              type="text"
              placeholder="Enter Name"
              className="placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
            />
          </Form.Item>
          <div className="grid grid-cols-2 md:grid-cols-4">
            <Form.Item
              name="remote_enabled"
              label=""
              className="theme-form-item w-full mb-8"
              valuePropName="checked"
            >
              <Checkbox
                onChange={e => {
                  if (e.target.checked) {
                    form.setFieldsValue({
                      location: null,
                    })
                    setLocationRequired(false)
                  } else {
                    setLocationRequired(true)
                  }
                }}
                className="search-filter-dropdown-item"
              >
                Remote
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="field_based"
              label=""
              className="theme-form-item w-full mb-8"
              valuePropName="checked"
            >
              <Checkbox className="search-filter-dropdown-item">Field</Checkbox>
            </Form.Item>
            <Tooltip title="Check this box if this job appears only MedReps and is not posted to other websites.">
              <Form.Item
                name="exclusive_medreps"
                label=""
                className="theme-form-item w-full mb-8"
                valuePropName="checked"
              >
                <Checkbox className="search-filter-dropdown-item">
                  Exclusive To Medreps
                </Checkbox>
              </Form.Item>
            </Tooltip>
            <Tooltip title="This will ensure that your company information is not displayed on the job listing. Only the title and description will display and the Company Profile will be obscured.">
              <Form.Item
                name="confidential"
                label=""
                className="theme-form-item w-full mb-8"
                valuePropName="checked"
              >
                <Checkbox className="search-filter-dropdown-item">
                  Post as Confidential
                </Checkbox>
              </Form.Item>
            </Tooltip>
          </div>

          <Form.Item
            name="location"
            label="Location"
            className="google-place-autocomplete w-full mb-8"
            rules={[
              {
                required: locationRequired && jobLocationList.length === 0,
                message: 'Required.',
              },
            ]}
          >
            <div className="flex flex-wrap">
              {jobLocationList.map((locItem, index) => (
                <div className="border py-2 px-4 rounded-full flex items-center mr-4 mb-2">
                  <span className="mr-4">{locItem?.label}</span>

                  <CloseOutlined onClick={() => handleRemoveLoc(index)} />
                </div>
              ))}
            </div>
            <GooglePlacesAutocomplete
              value={null}
              onChange={val => {
                if (jobLocationList.length < 5 && val) {
                  jobLocationList.push(val)
                  setJobLocationList([...jobLocationList])
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="theme-form-item w-full mb-8"
            name="compensation_type"
            label="Compensation Type"
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
          >
            <Select placeholder="Select Compensation Type">
              {CompensationType &&
                CompensationType?.map((primary, index) => {
                  return (
                    <Option key={index} value={valuesSetHandler(primary)}>
                      {primary}
                    </Option>
                  )
                })}
            </Select>
          </Form.Item>
          <Form.Item
            name="compensation"
            label="Average Total Compensation"
            className="theme-form-item w-full mb-8 multiple-slider"
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
          >
            <div className="flex items-center justify-between">
              <span className="font-semibold text-sm font-sans w-full flex justify-end -mt-[25px]">
                ${compensation[0]}K - ${compensation[1]}K
              </span>
            </div>
            <div className="mt-8">
              <Slider
                className="hidden lg:block"
                onChange={val => {
                  setCompensation(val)
                  setCompensationCopy(val)
                }}
                range
                value={compensation}
                max={500}
                min={0}
              />
              <div className="flex items-center mt-8">
                <InputNumber
                  value={compensation[0]}
                  min={0}
                  max={500}
                  type="number"
                  onChange={e => {
                    let _val = parseInt(e)
                    let _minMaxCopy = [...compensation]
                    if (_val) {
                      _minMaxCopy[0] = _val
                    } else {
                      _minMaxCopy[0] = 0
                    }
                    setCompensationCopy(_minMaxCopy)
                  }}
                  onPressEnter={() => {
                    let _minMaxCopy = [...compensationCopy]
                    let _val = _minMaxCopy[0]
                    if (_val && _val > _minMaxCopy[1]) {
                      message.error(
                        'Minimum compensation cannot be greater than maximum compensation'
                      )
                      setTimeout(() => {
                        setCompensationCopy([...compensation])
                      }, 500)
                      return
                    } else {
                      setCompensation(_minMaxCopy)
                    }
                  }}
                  onBlur={() => {
                    let _minMaxCopy = [...compensationCopy]
                    let _val = _minMaxCopy[0]
                    if (_val && _val > _minMaxCopy[1]) {
                      message.error(
                        'Minimum compensation cannot be greater than maximum compensation'
                      )
                      setTimeout(() => {
                        setCompensationCopy([...compensation])
                      }, 500)
                      return
                    } else {
                      setCompensation(_minMaxCopy)
                    }
                  }}
                  placeholder="Enter Minimum Compensation"
                  className="relative min-value w-4/12 placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
                />

                <InputNumber
                  onPressEnter={() => {
                    let _minMaxCopy = [...compensationCopy]
                    let _val = _minMaxCopy[1]
                    if (_val && _val > 500) {
                      message.error('Maximum compensation cannot exceed $500k')
                      setTimeout(() => {
                        setCompensationCopy([...compensation])
                      }, 500)

                      return
                    } else {
                      if (_val && _val < _minMaxCopy[0]) {
                        message.error(
                          'Maximum compensation cannot be less than minimum compensation'
                        )
                        setTimeout(() => {
                          setCompensationCopy([...compensation])
                        }, 500)
                        return
                      } else {
                        setCompensation(_minMaxCopy)
                      }
                    }
                  }}
                  onBlur={() => {
                    let _minMaxCopy = [...compensationCopy]
                    let _val = _minMaxCopy[1]
                    if (_val && _val > 500) {
                      message.error('Maximum compensation cannot exceed $500k')
                      setTimeout(() => {
                        setCompensationCopy([...compensation])
                      }, 500)

                      return
                    } else {
                      if (_val && _val < _minMaxCopy[0]) {
                        message.error(
                          'Maximum compensation cannot be less than minimum compensation'
                        )
                        setTimeout(() => {
                          setCompensationCopy([...compensation])
                        }, 500)
                        return
                      } else {
                        setCompensation(_minMaxCopy)
                      }
                    }
                  }}
                  value={compensation[1]}
                  type="number"
                  min={0}
                  placeholder="Enter Maximum Compensation"
                  className="relative max-value ml-8 w-4/12 placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
                  onChange={e => {
                    let _val = parseInt(e)
                    let _minMaxCopy = [...compensationCopy]
                    if (_val) {
                      _minMaxCopy[1] = _val
                    } else {
                      _minMaxCopy[1] = 0
                    }
                    setCompensationCopy(_minMaxCopy)
                  }}
                />
              </div>
            </div>
          </Form.Item>

          <Form.Item
            name="min_max"
            label=" Base Salary"
            className="theme-form-item w-full mb-8 multiple-slider"
          >
            <div className="flex items-center justify-between">
              <span className="font-semibold text-sm font-sans w-full flex justify-end -mt-[25px]">
                ${minMaxSalary[0]}K - ${minMaxSalary[1]}K
              </span>
            </div>
            <div className="mt-8">
              <Slider
                className="hidden lg:block"
                onChange={val => {
                  setMinMaxSalary(val)
                  setMinMaxSalaryCopy(val)
                }}
                range
                value={minMaxSalary}
                max={500}
                min={0}
              />
              <div className="flex items-center mt-8">
                <InputNumber
                  value={minMaxSalary[0]}
                  min={0}
                  max={500}
                  type="number"
                  onChange={e => {
                    let _val = parseInt(e)
                    let _minMaxCopy = [...minMaxSalaryCopy]
                    if (_val) {
                      _minMaxCopy[0] = _val
                    } else {
                      _minMaxCopy[0] = 0
                    }
                    setMinMaxSalaryCopy(_minMaxCopy)
                  }}
                  onPressEnter={() => {
                    let _minMaxCopy = [...minMaxSalaryCopy]
                    let _val = _minMaxCopy[0]
                    if (_val && _val > _minMaxCopy[1]) {
                      message.error(
                        'Minimum salary cannot be greater than maximum salary '
                      )
                      setTimeout(() => {
                        setMinMaxSalaryCopy([...minMaxSalary])
                      }, 500)
                      return
                    } else {
                      setMinMaxSalary(_minMaxCopy)
                    }
                  }}
                  onBlur={() => {
                    let _minMaxCopy = [...minMaxSalaryCopy]
                    let _val = _minMaxCopy[0]
                    if (_val && _val > _minMaxCopy[1]) {
                      message.error(
                        'Minimum salary cannot be greater than maximum salary '
                      )
                      setTimeout(() => {
                        setMinMaxSalaryCopy([...minMaxSalary])
                      }, 500)
                      return
                    } else {
                      setMinMaxSalary(_minMaxCopy)
                    }
                  }}
                  placeholder="Enter Minimum Salary"
                  className="relative min-value w-4/12 placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
                />

                <InputNumber
                  onPressEnter={() => {
                    let _minMaxCopy = [...minMaxSalaryCopy]
                    let _val = _minMaxCopy[1]
                    if (_val && _val > 500) {
                      message.error('Maximum salary cannot exceed $500k')
                      setTimeout(() => {
                        setMinMaxSalaryCopy([...minMaxSalary])
                      }, 500)

                      return
                    } else {
                      if (_val && _val < _minMaxCopy[0]) {
                        message.error(
                          'Maximum salary cannot be less than minimum salary '
                        )
                        setTimeout(() => {
                          setMinMaxSalaryCopy([...minMaxSalary])
                        }, 500)
                        return
                      } else {
                        setMinMaxSalary(_minMaxCopy)
                      }
                    }
                  }}
                  onBlur={() => {
                    let _minMaxCopy = [...minMaxSalaryCopy]
                    let _val = _minMaxCopy[1]
                    if (_val && _val > 500) {
                      message.error('Maximum salary cannot exceed $500k')
                      setTimeout(() => {
                        setMinMaxSalaryCopy([...minMaxSalary])
                      }, 500)

                      return
                    } else {
                      if (_val && _val < _minMaxCopy[0]) {
                        message.error(
                          'Maximum salary cannot be less than minimum salary '
                        )
                        setTimeout(() => {
                          setMinMaxSalaryCopy([...minMaxSalary])
                        }, 500)
                        return
                      } else {
                        setMinMaxSalary(_minMaxCopy)
                      }
                    }
                  }}
                  value={minMaxSalary[1]}
                  type="number"
                  min={0}
                  placeholder="Enter Maximum Salary"
                  className="relative max-value ml-8 w-4/12 placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
                  onChange={e => {
                    let _val = parseInt(e)
                    let _minMaxCopy = [...minMaxSalaryCopy]
                    if (_val) {
                      _minMaxCopy[1] = _val
                    } else {
                      _minMaxCopy[1] = 0
                    }
                    setMinMaxSalaryCopy(_minMaxCopy)
                  }}
                />
              </div>
            </div>
          </Form.Item>

          <Form.Item
            className="theme-form-item w-full mb-8"
            name="primary_function"
            label="Function"
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
          >
            <Select placeholder="Select Function">
              {jobFunctions &&
                jobFunctions?.map((primary, index) => {
                  return (
                    <Option key={index} value={valuesSetHandler(primary)}>
                      {primary}
                    </Option>
                  )
                })}
            </Select>
          </Form.Item>
          <Form.Item
            className="theme-form-item w-full mb-8"
            name="product_category"
            label="Industry"
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
          >
            <Select placeholder="Select Industry">
              {jobCategories &&
                jobCategories?.map((primary, index) => {
                  return (
                    <Option key={index} value={valuesSetHandler(primary)}>
                      {primary}
                    </Option>
                  )
                })}
            </Select>
          </Form.Item>

          <Form.Item
            label="Travel Percentage"
            name="travel_percentage"
            className="theme-form-item mb-8 w-full"
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
          >
            <Radio.Group>
              <Radio
                className="search-compensation-radio text-charcoal font-sans w-[40%] pb-4 lg:w-max lg:pt-0"
                value="0_25%"
              >
                0 -25%
              </Radio>
              <Radio
                className="search-compensation-radio font-sans w-[40%] pb-4 lg:w-max lg:pt-0"
                value="25_50%"
              >
                25-50%
              </Radio>
              <Radio
                className="search-compensation-radio  font-sans w-[40%] lg:w-max lg:pt-0"
                value="50_75%"
              >
                50-75%
              </Radio>
              <Radio
                className="search-compensation-radio  font-sans w-[40%] lg:w-max lg:pt-0"
                value="75_100%"
              >
                75-100%
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Application Method"
            name="application_method"
            className="theme-form-item mb-8 w-full"
            onChange={e => {
              setApplicationMethod(e.target.value)
            }}
          >
            <Radio.Group>
              <Radio
                className="search-compensation-radio  font-sans"
                value="externalRedirect"
              >
                External Redirect
              </Radio>
              <Radio
                className="search-compensation-radio  font-sans"
                value="email"
              >
                Email
              </Radio>
            </Radio.Group>
          </Form.Item>

          {applicationMethod === 'externalRedirect' && (
            <Form.Item
              name="applicationUrl"
              label=""
              className="theme-form-item w-full mb-8"
              rules={[
                {
                  required: true,
                  message: 'Required.',
                },
                {
                  type: 'url',
                  message: 'Enter a valid http or https url.',
                },
              ]}
            >
              <Input
                type="text"
                placeholder="Enter Application URL"
                className="placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
              />
            </Form.Item>
          )}
          {applicationMethod === 'email' && (
            <Form.Item
              name="application_email"
              className="theme-form-item w-full mb-8"
              rules={[
                {
                  required: true,
                  message: 'Required.',
                },
                {
                  type: 'email',
                  message: 'Invalid email address entered',
                },
              ]}
            >
              <Input
                type="email"
                placeholder="Enter Email Address For Applications"
                className="placeholder-[#C4C4C4] focus:border-merlot focus:outline-none focus:shadow-none"
              />
            </Form.Item>
          )}
          <div className="flex mt-4 w-full">
            {isActiveJob && (
              <button
                onClick={() => {
                  setFormMethod(FORM_METHOD_SUBMIT)
                  setTimeout(() => {
                    form.submit()
                  }, 500)
                }}
                type="button"
                className="bg-voyage py-3 px-10 text-white font-bold rounded text-sm font-sans mr-4"
              >
                {jobBtnLabel}
              </button>
            )}
            {!isActiveJob && (
              <>
                {products?.subscriptionPurchased || products?.singleJob ? (
                  <button
                    onClick={() => {
                      setFormMethod(FORM_METHOD_SUBMIT)
                      setTimeout(() => {
                        form.submit()
                      }, 500)
                    }}
                    type="button"
                    className="bg-voyage py-3 px-10 text-white font-bold rounded text-sm font-sans mr-4"
                  >
                    {jobBtnLabel}
                  </button>
                ) : (
                  <Tooltip
                    placement="topLeft"
                    title="Please purchase a subscription to post or update jobs."
                  >
                    <button
                      disabled
                      type="button"
                      className="bg-voyage py-3 px-10 text-white font-bold rounded text-sm font-sans mr-4"
                    >
                      Post Job
                    </button>
                  </Tooltip>
                )}
              </>
            )}
            <button
              onClick={() => {
                setFormMethod(FORM_METHOD_PREVIEW)
                form.submit()
              }}
              type="button"
              className="bg-voyage py-3 px-10 text-white font-bold rounded text-sm font-sans ml-4"
            >
              Preview
            </button>
          </div>
        </Form>
      </div>
      {!!jobPreview && (
        <EmployerJobPreview
          modalOpen={!!jobPreview}
          currentJob={jobPreview}
          currentJobEmployer={userProfile}
          onClose={() => setJobPreview(false)}
        />
      )}
    </div>
  )
}
export default JobEditComponent
